import React from 'react';
import { dayjs } from 'app/dayjs';
import { useBankAccounts } from 'modules/2023-q3/banking-page/v2/hooks/use-bank-accounts';
import { BankAccount } from 'modules/2023-q3/banking-page/v2/types/bank-account';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Currency, CurrencyVariant } from 'components/core';
import { Close } from '@material-ui/icons';
import { FrequencyOptions } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/pages/form/components/select-frequency/select-frequency';
import { DepositWithdrawalVariant } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import {
  ColumnType,
  DashboardTable,
  wrapTableText,
} from 'modules/dashboard/components';
import { TableText } from 'modules/dashboard/components/dashboard-table/components/table-text/table-text';
import { TransactionArrow } from './components/transaction-arrow/transaction-arrow';
import { DeleteScheduleTransfer } from './components/delete-schedule-transfer/delete-schedule-transfer';
import { Styled } from './schedule-transfer-table.style';

interface IScheduleTransfer {
  amount: string;
  bank_account_id: string;
  bankAccount?: BankAccount;
  direction: string;
  frequency: string;
  id: string;
  next_date: string;
}

interface IScheduleTransferTable {
  scheduleTransfers: IScheduleTransfer[];

  variant: DepositWithdrawalVariant | 'DepositAndWithdrawal';
}

const getTransactionType = (direction: string) => {
  switch (direction) {
    case 'IN':
      return 'Deposit';
    case 'OUT':
      return 'Withdrawal';
    default:
  }
  return null;
};

export const ScheduleTransferTable: React.FC<IScheduleTransferTable> = ({
  scheduleTransfers,
  variant,
}) => {
  const { data: bankAccounts } = useBankAccounts();
  const isDepositAndWithdrawal = variant === 'DepositAndWithdrawal';

  // sort by nextDate
  scheduleTransfers.sort(
    (a: IScheduleTransfer, b: IScheduleTransfer) =>
      new Date(a.next_date).getTime() - new Date(b.next_date).getTime(),
  );

  scheduleTransfers.map((st) => {
    st.bankAccount = bankAccounts?.find(
      (bankAccount: BankAccount) => bankAccount.Id === st.bank_account_id,
    );
  });

  const nextTransferColumnText = `Next ${
    isDepositAndWithdrawal
      ? 'Transfer'
      : variant === DepositWithdrawalVariant.Deposit
      ? 'Deposit'
      : 'Withdrawal'
  }`;

  let columnsTransactions: ColumnType[] = [
    { alignment: 'center', name: 'Account', size: 3 },
    { alignment: 'center', name: 'Amount', size: 3 },
    { alignment: 'center', name: nextTransferColumnText, size: 3 },
    { alignment: 'center', name: 'Frequency', size: 2 },
    { alignment: 'center', size: 1 },
  ];

  if (isDepositAndWithdrawal) {
    columnsTransactions = [
      { name: 'Transaction Type', size: 4 },
      { alignment: 'center', name: 'Account', size: 2 },
      { alignment: 'center', name: 'Amount', size: 2 },
      { alignment: 'center', name: nextTransferColumnText, size: 2 },
      { alignment: 'center', name: 'Frequency', size: 1 },
      { alignment: 'center', size: 1 },
    ];
  }

  const tableData: any[] = [];

  scheduleTransfers.map((scheduleTransfer) => {
    const row = [
      <TableText>
        {scheduleTransfer.bankAccount?.Mask !== null &&
          scheduleTransfer.bankAccount?.Mask !== undefined &&
          `${scheduleTransfer.bankAccount?.Name} (...${scheduleTransfer.bankAccount?.Mask})`}
      </TableText>,

      wrapTableText(
        <Currency
          number={+scheduleTransfer.amount}
          variant={CurrencyVariant.Full}
        />,
      ),

      wrapTableText(dayjs(scheduleTransfer.next_date).format('MMMM D, YYYY')),

      <TableText>
        {
          FrequencyOptions.find(
            (frequencyOption) =>
              frequencyOption.value === scheduleTransfer.frequency,
          )?.label
        }
      </TableText>,

      <DeleteScheduleTransfer id={scheduleTransfer.id} />,
    ];

    if (isDepositAndWithdrawal) {
      row.unshift(
        wrapTableText(
          <>
            <TransactionArrow direction={scheduleTransfer.direction} />
            {getTransactionType(scheduleTransfer.direction)}
          </>,
        ),
      );
    }

    return tableData.push(row);
  });

  return <DashboardTable columns={columnsTransactions} data={tableData} />;
};
