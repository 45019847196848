import React, { useState } from 'react';
import { i18nNamespace } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation, Trans } from 'app/i18n';
import { Box, Divider, Grid } from '@material-ui/core';
import {
  Accordion,
  AccordionDetails,
} from 'modules/2023-q3/components/accordion/accordion';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { useBusiness } from 'hooks/business/use-business';
import { LiquiditySummary } from './components/liquidity-summary/liquidity-summary';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { TreasureFiEmails } from 'enums';
import { useTracking, TrackEventName } from 'modules/tracking';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';

export const LiquidityBreakdown = () => {
  const { Track, trackEvent } = useTracking<{
    component: string;
    eventName?: TrackEventName;
    expanded?: boolean;
  }>({
    component: 'Portfolio Liquidity',
  });
  const { t } = useTranslation(i18nNamespace);

  const [isExpanded, setIsExpanded] = useState(false);

  const { data: business } = useBusiness();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);

  const has3DayLiquidity = aggregateBalances?.liquidity.threeDay.amount > 0.0;

  const handleAccordionChange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setIsExpanded(isExpanded);

    trackEvent({
      component: 'Portfolio Liquidity',
      eventName: TrackEventName.Click,
      expanded: isExpanded,
    });
  };

  const ContactSupport: React.FC = () => (
    <ContactTreasureSupportLink subject="3-5 Day Liquidity Withdrawal">
      <Typography color="blue2" variant={TypographyVariant.Paragraph2}>
        {TreasureFiEmails.Support}
      </Typography>
    </ContactTreasureSupportLink>
  );

  const LiquidityRow = ({
    amount,
    label,
  }: {
    amount: number;
    label: string;
  }) => (
    <>
      <Divider />

      <Box py={2}>
        <Grid container>
          <Grid item xs>
            <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
              {label}
            </Typography>
          </Grid>

          <Grid item xs>
            <Box textAlign="right">
              <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
                <Currency number={amount} variant={CurrencyVariant.Full} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  if (isLoadingAggregateBalances) {
    return null;
  }

  return (
    <Track>
      <FormContainer>
        <Accordion onChange={handleAccordionChange}>
          <LiquiditySummary isExpanded={isExpanded} />

          <AccordionDetails>
            <Box mt={2}>
              <LiquidityRow
                amount={aggregateBalances?.liquidity.threeDay.amount}
                label={t('3-5 Day Liquidity')}
              />

              <LiquidityRow
                amount={aggregateBalances?.liquidity.pending.amount}
                label={t('Pending')}
              />

              <LiquidityRow
                amount={aggregateBalances?.liquidity.total.amount}
                label={t('Total')}
              />

              <FeatureFlag
                disabled={
                  <>
                    {has3DayLiquidity && (
                      <>
                        <Divider />

                        <Box mt={2}>
                          <Typography
                            color="black19"
                            variant={TypographyVariant.Paragraph2}
                          >
                            <Trans
                              t={t}
                              i18nKey="Liquidity Unsupported"
                              components={{
                                supportEmail: <ContactSupport />,
                              }}
                            />
                          </Typography>
                        </Box>
                      </>
                    )}
                  </>
                }
                flag={
                  FeatureFlags.REACT_APP_TRANSFER_LIQUIDITY_WITHDRAWAL_ENABLED
                }
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </FormContainer>
    </Track>
  );
};
