import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useCreateScheduleTransfer = (): UseMutationResult<
  any,
  any,
  any
> => {
  const queryClient = useQueryClient();

  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  const createScheduleTransfer = async ({
    amount,
    bankAccountId,
    direction,
    frequency,
    idempotencyKey,
    startDate,
  }: {
    amount: string;
    bankAccountId: string;
    direction: string;
    frequency: string;
    idempotencyKey: string;
    startDate: string;
  }) => {
    const response = await treasureApi().post('/transfer/schedule', {
      amount,
      bankAccountId,
      direction,
      frequency,
      idempotencyKey,
      startDate,
    });

    return response.data;
  };

  return useMutation(createScheduleTransfer, {
    onSuccess: () => {
      // TODO: remove old DashboardActivity
      queryClient.invalidateQueries([ReactQueryKeys.DashboardActivity]);
      queryClient.invalidateQueries([ReactQueryKeys.DashboardActivityGateway]);
      return queryClient.invalidateQueries([ReactQueryKeys.ScheduleTransfers]);
    },
  });
};
