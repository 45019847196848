import { useTreasureApi, ApiKeys } from 'hooks/api/use-treasure-api';
import { useQuery, UseQueryResult } from 'react-query';
import { ReactQueryKeys } from 'enums';

export const useTreasureReturns = (): UseQueryResult<any> => {
  const { treasureApi } = useTreasureApi(ApiKeys.PortalGateway);

  return useQuery([ReactQueryKeys.MonthlyReturns], async () => {
    const response = await treasureApi().get(`/business/returns/month`);

    return response.data;
  });
};
