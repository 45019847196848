import React from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { SectionContainer, SectionTitle } from 'modules/dashboard/components';
import { useBusiness } from 'hooks/business/use-business';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { useTreasureReturns } from 'modules/2025-q1/treasure-returns/hooks/use-treasure-returns';
import { useFees } from 'hooks/custodian/use-fees';
import { MonthlyReturn } from 'modules/dashboard/treasure-reserve/returns/components/returns-chart/returns-chart';
import { useDashboardActivity } from 'hooks/portal-gateway/use-dashboard-activity';
import { Transaction } from 'modules/dashboard/treasure-reserve/transactions/components/transactions-table/transactions-table';

import { Greeting } from './components/greeting/greeting';

import { Styled } from './overview.style';

const OverviewNumber = ({ label, value }: { label: string; value: number }) => {
  return (
    <>
      <Typography variant={TypographyVariant.Paragraph2}>{label}</Typography>

      <Typography variant={TypographyVariant.HeaderRegular}>
        <Currency number={value} variant={CurrencyVariant.Full} />
      </Typography>
    </>
  );
};

export const Overview = () => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: monthlyReturns, isLoading: isLoadingMonthlyReturns } =
    useTreasureReturns();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);
  const { data: fees, isLoading: isLoadingFees } = useFees(business?.Id);

  // filter out partial fees
  const feesToDisplay = fees?.filter(
    (fee: { parentfee: string }) => fee.parentfee === null,
  );

  // sort the fees newest to oldest
  feesToDisplay?.sort((a: { calcDate: string }, b: { calcDate: string }) =>
    a.calcDate > b.calcDate ? -1 : 1,
  );

  // sort the returns newest to oldest
  monthlyReturns?.returns?.sort(
    (a: { start_date: string }, b: { start_date: string }) =>
      a.start_date > b.start_date ? -1 : 1,
  );

  // TODO: temporarily use transactions data while account balances gets fixed
  const { data: dashboardActivity, isLoading: isLoadingDashboardActivity } =
    useDashboardActivity();
  const pendingTransactions = dashboardActivity?.transactions?.filter(
    (transaction: Transaction) =>
      transaction.status === 'IN_PROGRESS' || transaction.status === 'PENDING',
  );

  const totalBalance = aggregateBalances?.aum?.total?.amount;

  const pendingDeposits = pendingTransactions?.reduce(
    (sum: number, pendingTransaction: Transaction) =>
      sum +
      (pendingTransaction.description.includes('into')
        ? Number(pendingTransaction.amount)
        : 0),
    0,
  );
  const pendingWithdrawals = pendingTransactions?.reduce(
    (sum: number, pendingTransaction: Transaction) =>
      sum +
      (pendingTransaction.description.includes('out')
        ? Number(pendingTransaction.amount)
        : 0),
    0,
  );
  const lastMonthLabel = dayjs(monthlyReturns?.returns[0]?.start_date).format(
    'MMM',
  );
  const lastMonthEarnings = monthlyReturns?.returns[0]?.returns_in_dollars;

  const latestFeeMonthLabel = dayjs(
    feesToDisplay?.[0]?.calcDate?.substr(0, 10),
  ).format('MMM');

  if (
    isLoadingBusiness ||
    isLoadingDashboardActivity ||
    isLoadingFees ||
    isLoadingMonthlyReturns ||
    isLoadingAggregateBalances
  ) {
    return null;
  }

  return (
    <>
      <Box mb={6}>
        <Greeting />
      </Box>

      <Grid container spacing={4}>
        <Grid item>
          <Box mr={8}>
            <Typography variant={TypographyVariant.Body}>
              Balance Total
            </Typography>

            <Typography variant={TypographyVariant.LargeHeader}>
              <Currency number={totalBalance} variant={CurrencyVariant.Full} />
            </Typography>

            {/*<Grid container spacing={2}>
              <Grid item>
                <Typography
                  color="grey4"
                  variant={TypographyVariant.Paragraph2}
                >
                  {' '}
                  <Currency
                    number={availableBalance}
                    variant={CurrencyVariant.Full}
                  />{' '}
                  Available
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="grey4"
                  variant={TypographyVariant.Paragraph2}
                >
                  <Currency
                    number={pendingBalance}
                    variant={CurrencyVariant.Full}
                  />{' '}
                  Pending
                </Typography>
              </Grid>
            </Grid>*/}
          </Box>
        </Grid>

        <Grid item>
          <OverviewNumber label="Pending Deposits" value={pendingDeposits} />
        </Grid>

        <Grid item>
          <OverviewNumber
            label="Pending Withdrawals"
            value={pendingWithdrawals}
          />
        </Grid>

        {monthlyReturns?.returns[0] && (
          <Grid item>
            <OverviewNumber
              label={`${lastMonthLabel} Returns`}
              value={lastMonthEarnings}
            />
          </Grid>
        )}

        {feesToDisplay.length > 0 && (
          <Grid item>
            <OverviewNumber
              label={`${latestFeeMonthLabel} Fee`}
              value={feesToDisplay?.[0].amount}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
