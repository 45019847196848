import React, { useEffect } from 'react';
import {
  i18nNamespace,
  IDepositWithdrawal,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation } from 'app/i18n';
import { Spinner } from 'components/core';
import {
  SelectSwitch,
  SelectSwitchOption,
} from 'modules/2023-q3/components/forms/components/select-switch/select-switch';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useBusiness } from 'hooks/business/use-business';
import { BankAccount } from 'types';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { SelectSwitchACHBankOption } from './components/select-switch-ach-bank-option/select-switch-ach-bank-option';
import { Styled } from './select-switch-ach-bank.style';

export interface ISelectACHBank extends IDepositWithdrawal {
  bank: BankAccount | undefined;

  setBank: (bank: BankAccount | undefined) => void;
}

interface ISelectSwitchACHBank extends ISelectACHBank {
  isSelectingBank: boolean;

  setIsSelectingBank: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectSwitchACHBank: React.FC<ISelectSwitchACHBank> = ({
  bank,
  isSelectingBank,
  setBank,
  setIsSelectingBank,
  variant,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;

  const { data: business } = useBusiness();
  const { data: accounts, isLoading: isLoadingBankAccounts } = useBankAccounts(
    business?.Id,
  );

  const checkingAndSavingsBankAccounts = accounts?.filter(
    (account: BankAccount) => ['checking', 'savings'].includes(account.Type),
  );

  const achBankAccounts = checkingAndSavingsBankAccounts?.filter(
    (account: BankAccount) =>
      account.FinancialDataAggregator === 'PLAID' &&
      account.PlaidDataReady === true,
  );

  // ins_12 Fidelity
  // ins_13 Chase
  // ins_56 PNC
  const wireBankAccounts = checkingAndSavingsBankAccounts?.filter(
    (account: BankAccount) =>
      !(
        account.FinancialDataAggregator === 'PLAID' &&
        account.institution.id === 'ins_12'
      ) &&
      !(
        account.FinancialDataAggregator === 'PLAID' &&
        account.institution.id === 'ins_13'
      ) &&
      !(
        account.FinancialDataAggregator === 'PLAID' &&
        account.institution.id === 'ins_56'
      ),
  );

  const getSelectSwitchOptions = () => {
    const options: SelectSwitchOption[] = [];

    if (isDeposit) {
      achBankAccounts?.map((account: BankAccount) => {
        options.push({
          label: <SelectSwitchACHBankOption bankAccount={account} />,
          value: account.Id,
        });
      });
    } else {
      wireBankAccounts?.map((account: BankAccount) => {
        options.push({
          label: <SelectSwitchACHBankOption bankAccount={account} />,
          value: account.Id,
        });
      });
    }

    return options;
  };

  const handleOnChange = (selectedOption: SelectSwitchOption) => {
    setBank(
      isDeposit
        ? achBankAccounts?.find(
            (bank: BankAccount) => bank.Id === selectedOption.value,
          )
        : wireBankAccounts?.find(
            (bank: BankAccount) => bank.Id === selectedOption.value,
          ),
    );
  };

  const selectSwitch = (
    <SelectSwitch
      isSelecting={isSelectingBank}
      label={t(isDeposit ? 'Source Bank' : 'Destination Bank', {
        count: isDeposit ? achBankAccounts?.length : wireBankAccounts?.length,
      })}
      onChange={handleOnChange}
      options={getSelectSwitchOptions()}
      selectedOption={getSelectSwitchOptions().find(
        (option) => option.value === bank?.Id,
      )}
      setIsSelecting={setIsSelectingBank}
    />
  );

  // default the first bank account
  useEffect(() => {
    if (isDeposit) {
      if (!bank?.Id && achBankAccounts?.length) {
        const firstBank = achBankAccounts?.find(
          (bank: BankAccount) => bank.Id === achBankAccounts[0].Id,
        );

        setBank(firstBank);
      }
    } else {
      if (!bank?.Id && wireBankAccounts?.length) {
        const firstBank = wireBankAccounts?.find(
          (bank: BankAccount) => bank.Id === wireBankAccounts[0].Id,
        );

        setBank(firstBank);
      }
    }
  }, [isDeposit, achBankAccounts, wireBankAccounts]);

  if (isLoadingBankAccounts) {
    return <Spinner />;
  }

  return (
    <MobileView mobile={selectSwitch}>
      <Styled.SelectSwitchContainer isSelecting={isSelectingBank}>
        {selectSwitch}
      </Styled.SelectSwitchContainer>
    </MobileView>
  );
};
