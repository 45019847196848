import React from 'react';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';
import { useFeatureFlags, FeatureFlags } from 'utils/feature-flags';
import { Deposit as DepositApexACH } from 'modules/2024-q4/apex-ach/deposit-apex-ach/deposit';
import { DepositContainer } from 'modules/2024-q4/apex-ach/deposit-container/deposit-container';
import { DepositWithdrawalSteps } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';

export const CheckAccountStatusBeforeDeposit = () => {
  const { data: business } = useBusiness();
  const { data: treasureReserve } = useTreasureReserve(business?.Id);

  const { isFeatureFlagEnabled } = useFeatureFlags();

  // if the account has an apex account number, display the apex wire instructions
  if (treasureReserve?.custodianAccountNumber) {
    // if Apex ACH is enabled, show the flow
    if (isFeatureFlagEnabled(FeatureFlags.REACT_APP_2024_Q4_APEX_ACH)) {
      return <DepositApexACH />;
    }

    return <DepositContainer step={DepositWithdrawalSteps.WireInstructions} />;
  }

  return <DepositContainer step={DepositWithdrawalSteps.SettingUpAccount} />;
};
