import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'enums';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Button } from 'modules/2023-q3/components/button/button';
import { ButtonContent } from 'modules/2023-q3/components/button/button-content/button-content';
import { useForm } from 'react-hook-form';
import { useTracking, TrackEventName } from 'modules/tracking';

import { ManualWireBankAccount } from './types/manual-wire-bank-account';
import { useManualWireBankAccountConnect } from './hooks/use-manual-wire-bank-account-connect';

import {
  AutocompleteController,
  TextFieldController,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';

import { formFields, BankAccountType } from './manual-wire-form-fields';

const gridContainerSpacing = 3;
const gridItemSize = 12;

interface ManualWireFormProps {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManualWireForm: React.FC<ManualWireFormProps> = ({
  setIsExpanded,
}) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const { trackEvent } = useTracking<{ eventName: TrackEventName }>();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<ManualWireBankAccount>();
  const manualBankAccountConnect = useManualWireBankAccountConnect();

  useEffect(() => {
    formErrorFocus(errors, isDirty);
  }, [errors, isDirty]);

  const onSubmit = (data: ManualWireBankAccount) => {
    setIsLoading(true);

    data.accountType = data.accountType.toLowerCase();
    manualBankAccountConnect.mutate(data, {
      onSuccess: () => {
        queryClient.refetchQueries(ReactQueryKeys.BankAccounts).then(() => {
          setIsLoading(false);
          setIsExpanded(false);
        });
      },
    });

    trackEvent({
      eventName: TrackEventName.ManualWireFormCompleted,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={gridContainerSpacing}>
        <Grid item xs={gridItemSize}>
          <TextFieldController {...formFields.bankName} control={control} />
        </Grid>

        <Grid item xs={gridItemSize}>
          <TextFieldController
            {...formFields.wireRoutingNumber}
            control={control}
          />
        </Grid>

        <Grid item xs={gridItemSize}>
          <TextFieldController
            {...formFields.accountNumber}
            control={control}
          />
        </Grid>

        <Grid item xs={gridItemSize}>
          <AutocompleteController
            {...formFields.accountType}
            control={control}
          />
        </Grid>
      </Grid>

      <Box mt={5}>
        <Button fullWidth type="submit" disabled={isLoading}>
          <ButtonContent>
            <Typography variant={TypographyVariant.Body} color="white">
              {isLoading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                'Connect Account'
              )}
            </Typography>
          </ButtonContent>
        </Button>
      </Box>
    </form>
  );
};
