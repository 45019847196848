import React, { useState } from 'react';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'modules/2023-q3/components/accordion/accordion';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrows/caret-down.svg';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { useBankAccounts } from 'hooks/banking/use-bank-accounts';
import { useBusiness } from 'hooks/business/use-business';
import { BankAccount } from 'types';
import { ManualWireForm } from './manual-wire-form/manual-wire-form';

export const ManualWireFormAccordion = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: business } = useBusiness();
  const { data: accounts, isLoading: isLoadingBankAccounts } = useBankAccounts(
    business?.Id,
  );

  // ins_12 Fidelity
  // ins_13 Chase
  // ins_56 PNC
  const wireBankAccounts = accounts?.filter(
    (account: BankAccount) =>
      (account.FinancialDataAggregator === 'PLAID' &&
        account.institution.id === 'ins_12') ||
      (account.FinancialDataAggregator === 'PLAID' &&
        account.institution.id === 'ins_13') ||
      (account.FinancialDataAggregator === 'PLAID' &&
        account.institution.id === 'ins_56'),
  );

  const handleAccordionChange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setIsExpanded(isExpanded);
  };

  if (wireBankAccounts.length > 0) {
    return (
      <FormContainer>
        <Accordion onChange={handleAccordionChange} expanded={isExpanded}>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box>
                      <Typography
                        color="grey1"
                        variant={TypographyVariant.Paragraph1}
                      >
                        Add Wire Information
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Typography
                  color="grey1"
                  variant={TypographyVariant.Paragraph2}
                >
                  Enter your bank account&apos;s wire routing number and account
                  number.
                  <br />
                  <br />
                  <strong>Chase</strong> and <strong>PNC</strong> customers that
                  added their bank accounts with Plaid need to re-enter their
                  account numbers.
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Box mt={4}>
              <ManualWireForm setIsExpanded={setIsExpanded} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </FormContainer>
    );
  }

  return null;
};
