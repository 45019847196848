import React from 'react';
import {
  DepositWithdrawalSteps,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useLocation } from 'react-router-dom';
import { Header } from 'modules/2023-q3/deposit-withdrawal/components/header/header';
import { CenteredContainer } from 'layouts/v3-portal-layout/components/centered-container';
import { ApexWireInstructions } from 'modules/2024-q3/grasshopper-sunset/deposit/deposit-apex-wire-instructions/components/apex-wire-instructions/apex-wire-instructions';
import { DepositSettingUpAccount } from 'modules/2024-q4/apex-ach/deposit-setting-up-account/deposit-setting-up-account';

interface DepositProps {
  hideContainer?: boolean;
  hideHeader?: boolean;
  step: DepositWithdrawalSteps;
}

export const DepositContainer: React.FC<DepositProps> = ({
  hideContainer,
  hideHeader,
  step,
}) => {
  const { state }: { state: any } = useLocation();
  const from = state?.from;

  const previousStep = () => {
    return null;
  };

  const displayStep = (step: DepositWithdrawalSteps) => {
    switch (step) {
      case DepositWithdrawalSteps.SettingUpAccount:
        return <DepositSettingUpAccount from={from} />;
      case DepositWithdrawalSteps.WireInstructions:
        return <ApexWireInstructions from={from} />;
      default:
        return <ApexWireInstructions from={from} />;
    }
  };

  return (
    <>
      {!hideHeader && (
        <Header
          from={from}
          previousStep={previousStep}
          step={step}
          variant={DepositWithdrawalVariant.Deposit}
        />
      )}

      {hideContainer ? (
        displayStep(step)
      ) : (
        <CenteredContainer>{displayStep(step)}</CenteredContainer>
      )}
    </>
  );
};
