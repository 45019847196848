import React from 'react';
import { i18nNamespace } from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation, Trans } from 'app/i18n';
import { Grid } from '@material-ui/core';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { useBusiness } from 'hooks/business/use-business';
import { EstimatedArrivalBox } from './components/estimated-arrival-box/estimated-arrival-box';

interface Props {
  amount: number;
}

export const EstimatedArrivalBoxes: React.FC<Props> = ({ amount }) => {
  const { t } = useTranslation(i18nNamespace);

  const { data: business } = useBusiness();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);

  const oneDayAmount = Math.min(
    amount,
    aggregateBalances?.liquidity.oneDay.amount,
  );
  const threeDayAmonut = Math.min(
    amount - oneDayAmount,
    aggregateBalances?.liquidity.threeDay.amount,
  );

  return (
    <Grid container spacing={2}>
      {threeDayAmonut > 0.0 && (
        <Grid item xs={6}>
          <EstimatedArrivalBox
            amount={threeDayAmonut}
            title={t('Est. Arrival is 3-5 Business Days')}
          />
        </Grid>
      )}
    </Grid>
  );
};
