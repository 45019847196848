import React from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Divider } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Percentage,
  Typography,
  TypographyVariant,
} from 'components/core';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { MonthlyReturn } from 'modules/dashboard/treasure-reserve/returns/components/returns-chart/returns-chart';
import { Styled } from './returns-chart-tooltip.style';

interface ReturnsChartTooltip extends TooltipProps<ValueType, NameType> {
  monthlyReturnData: MonthlyReturn[];
}

export const ReturnsChartTooltip: React.FC<ReturnsChartTooltip> = ({
  active,
  label,
  payload,
  monthlyReturnData,
}) => {
  const firstOfMonth = dayjs(payload?.[0].payload.date).format('YYYY-MM-01');
  const monthLabel = dayjs(payload?.[0].payload.date).format('MMMM');

  const monthReturn = monthlyReturnData?.find(
    (month) => month.start_date === firstOfMonth,
  );

  return (
    <Styled.TooltipContainer p={1}>
      <Box mb={1}>
        <Typography variant={TypographyVariant.Header4}>
          {dayjs(payload?.[0].payload.date).format('MMM DD, YYYY')}
        </Typography>
      </Box>

      <Box m={2}>
        <Divider />
      </Box>

      <Box mb={1}>
        <Typography variant={TypographyVariant.Paragraph1}>
          Total Balance:{' '}
          <Currency
            number={payload?.[0].payload.amount}
            variant={CurrencyVariant.Full}
          />
        </Typography>
      </Box>

      <Box mb={1}>
        <Typography variant={TypographyVariant.Paragraph1}>
          {monthLabel} Total Return:{' '}
          <Currency
            number={Number(monthReturn?.returns_in_dollars)}
            variant={CurrencyVariant.Full}
          />
        </Typography>
      </Box>

      <Box>
        <Typography variant={TypographyVariant.Paragraph1}>
          {monthLabel} Annualized Return:{' '}
          <Percentage
            decimalScale={2}
            number={Number(monthReturn?.returns_percentage_annualized)}
          />
        </Typography>
      </Box>
    </Styled.TooltipContainer>
  );
};
