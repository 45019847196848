export const i18nNamespace = 'deposit-withdrawal-content';

export enum DepositWithdrawalSteps {
  Form,
  Review,
  MFA,
  Processing,
  Confirmation,
  FailedRetry,
  LiquidityUnsupported,
  WireInstructions,
  SettingUpAccount,
}

export enum DepositWithdrawalVariant {
  'Deposit',
  'Withdrawal',
}

export interface IDepositWithdrawal {
  variant: DepositWithdrawalVariant;
}
