import React from 'react';
import { i18n, useTranslation } from 'app/i18n';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';
import { Box } from '@material-ui/core';
import { Spinner, Typography, TypographyVariant } from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import { InstructionRow } from 'modules/2023-q3/deposit-withdrawal/pages/wire-instructions/components/instruction-row/instruction-row';
import WireInstructionsContent from 'modules/2023-q3/deposit-withdrawal/pages/wire-instructions/content/wire-instructions.content.json';

import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';

interface Props {
  from?: { pathname: string };
  previousStep?: () => void;
}

export const DepositSettingUpAccount: React.FC<Props> = ({
  from,
  previousStep,
}) => {
  const { t } = useTranslation();

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: treasureReserve, isLoading: isLoadingTreasureReserve } =
    useTreasureReserve(business?.Id);

  const PreviousButton = ({ mobile }: { mobile?: boolean }) => {
    return (
      <Box mt={mobile ? 10 : undefined}>
        {previousStep ? (
          <Button
            borderRadius={mobile ? 0 : undefined}
            bottomFixed={mobile}
            fullWidth
            onClick={previousStep}
          >
            <ButtonContent iconStart={SupportedIcons.ArrowLeft} mobile={mobile}>
              {t('Previous', { ns: 'app' })}
            </ButtonContent>
          </Button>
        ) : (
          <Link to={from?.pathname || RoutesPath.pages.dashboard.path}>
            <Button
              borderRadius={mobile ? 0 : undefined}
              bottomFixed={mobile}
              fullWidth
            >
              <ButtonContent
                iconStart={SupportedIcons.ArrowLeft}
                mobile={mobile}
              >
                {t('Previous', { ns: 'app' })}
              </ButtonContent>
            </Button>
          </Link>
        )}
      </Box>
    );
  };

  return (
    <FormContainer>
      <Box mb={3}>
        <Typography color="black19" variant={TypographyVariant.Header3}>
          Deposit
        </Typography>
      </Box>

      {isLoadingBusiness || isLoadingTreasureReserve ? (
        <Box m={10}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Typography color="grey1" variant={TypographyVariant.Paragraph1}>
              We&apos;re working on verifying and setting up your account.
            </Typography>
          </Box>

          <Typography color="grey1" variant={TypographyVariant.Paragraph1}>
            Once your account is ready, you&apos;ll be able to make a deposit
            here.
          </Typography>
        </>
      )}

      <MobileView mobile={<PreviousButton mobile />}>
        <Box mt={4}>
          <PreviousButton />
        </Box>
      </MobileView>
    </FormContainer>
  );
};
