import React, { useEffect, useState } from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Grid } from '@material-ui/core';
import { Spinner } from 'components/core';
import {
  SectionContainer,
  SectionTitle,
  TimeFilterOption,
  TimeFilters,
  getTimeFilterDateRange,
  isDateInTimeFilter,
} from 'modules/dashboard/components';
import { ResponsiveScrollContent } from 'modules/dashboard/components/responsive-scroll-content/responsive-scroll-content';
import {
  Period,
  useAUMBalances,
} from 'modules/dashboard/treasure-reserve/returns/hooks/use-aum-balances';
import { useTreasureReturns } from 'modules/2025-q1/treasure-returns/hooks/use-treasure-returns';
import { useBusiness } from 'hooks/business/use-business';
import { useTreasureReserve } from 'hooks/custodian/use-treasure-reserve';
import { useTracking, TrackPageSection, TrackView } from 'modules/tracking';
import { ReturnsChart, ReturnsChartKey, ReturnsTable } from './components';
import { MonthlyReturn } from 'modules/dashboard/treasure-reserve/returns/components/returns-chart/returns-chart';

// TODO: take divider out into a common component or styled file
import { Styled } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-table/product-table.style';

const defaultTimeFilter = TimeFilterOption.SIX_MONTH;

const filterMonthlyReturnsByTimeFilter = (
  monthlyReturnsData: MonthlyReturn[],
  timeFilter: TimeFilterOption,
) => {
  // because we're not showing the current month, we need to shift 1 month
  return monthlyReturnsData?.filter((monthlyReturn) => {
    return isDateInTimeFilter(
      dayjs(monthlyReturn.start_date).add(1, 'month').format('YYYY-MM-DD'),
      timeFilter,
    );
  });
};

export const Returns: React.FC = () => {
  const { Track } = useTracking<{
    section: TrackPageSection;
  }>({
    section: TrackPageSection.TreasureReserveDashboardReturns,
  });

  const { Track: TrackChart } = useTracking<{
    component: string;
    section: TrackPageSection;
  }>({
    component: 'Returns Chart',
    section: TrackPageSection.TreasureReserveDashboardReturns,
  });

  const { Track: TrackTable } = useTracking<{
    component: string;
    section: TrackPageSection;
  }>({
    component: 'Returns Table',
    section: TrackPageSection.TreasureReserveDashboardReturns,
  });

  const returnsTitle = 'Treasure Reserve Balance & Returns';

  const [timeFilter, setTimeFilter] =
    useState<TimeFilterOption>(defaultTimeFilter);

  const [dates, setDates] = useState(getTimeFilterDateRange(timeFilter));

  const { data: aumBalances, isLoading: isLoadingAUMBalances } = useAUMBalances(
    {
      endDate: dates.endDate,
      period: Period.DAILY,
      startDate: dates.startDate,
    },
  );

  const { data: monthlyReturns, isLoading: isLoadingMonthlyReturns } =
    useTreasureReturns();

  const { data: business } = useBusiness();
  const { data: treasureReserve } = useTreasureReserve(business?.Id);

  useEffect(() => {
    // TODO: alternatively, we could get all the data and then filter it
    // based on the time filter
    setDates(getTimeFilterDateRange(timeFilter));
  }, [setDates, timeFilter]);

  if (treasureReserve && treasureReserve?.netDeposits <= 0) {
    return null;
  }

  if (isLoadingMonthlyReturns) {
    return (
      <Box mt={4}>
        <Box mb={2}>
          <SectionTitle title={returnsTitle} />
        </Box>

        <SectionContainer>
          <Box p={10}>
            <Spinner />
          </Box>
        </SectionContainer>
      </Box>
    );
  }

  return (
    <Track>
      <Box mt={4}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <SectionTitle title={returnsTitle} />
            </Grid>

            <Grid item xs={12} sm>
              <Box textAlign="right">
                <TimeFilters
                  defaultTimeFilter={defaultTimeFilter}
                  setTimeFilter={setTimeFilter}
                  timeFilterOptions={[
                    TimeFilterOption.TWELVE_MONTH,
                    TimeFilterOption.SIX_MONTH,
                    TimeFilterOption.THREE_MONTH,
                    TimeFilterOption.ONE_MONTH,
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <SectionContainer p={0}>
          <Box py={2}>
            <TrackChart>
              <TrackView />

              <ReturnsChart
                dailyAUMData={aumBalances?.data}
                monthlyReturnData={filterMonthlyReturnsByTimeFilter(
                  monthlyReturns?.returns,
                  timeFilter,
                )}
                timeFilter={timeFilter}
              />

              <Box p={4}>
                <ReturnsChartKey />
              </Box>
            </TrackChart>

            <Styled.Divider />

            <TrackTable>
              <TrackView />

              <ResponsiveScrollContent widthToSubtract={34}>
                <Box px={2} py={3}>
                  <ReturnsTable
                    data={monthlyReturns?.returns}
                    timeFilter={timeFilter}
                  />
                </Box>
              </ResponsiveScrollContent>
            </TrackTable>
          </Box>
        </SectionContainer>
      </Box>
    </Track>
  );
};
