import React, { useState } from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Collapse } from '@material-ui/core';
import { Currency, CurrencyVariant, Percentage } from 'components/core';
import {
  ColumnType,
  TimeFilterOption,
  isDateInTimeFilter,
  wrapTableText,
} from 'modules/dashboard/components';
import { TableBody } from 'modules/dashboard/components/dashboard-table/components/table-body/table-body';
import { TableHeader } from 'modules/dashboard/components/dashboard-table/components/table-header/table-header';
import { useTracking, TrackEventName } from 'modules/tracking';
import { MonthlyReturn } from 'modules/dashboard/treasure-reserve/returns/components/returns-chart/returns-chart';
import { useBusiness } from 'hooks/business/use-business';
import { useFees } from 'hooks/custodian/use-fees';

// TODO: take divider out into a common component or styled file
import { Styled } from 'modules/dashboard/treasure-reserve/portfolio/components/portfolio-view/components/product-table/product-table.style';

const columnsReturnsTable: ColumnType[] = [
  { name: 'Time Period', size: 4 },
  { alignment: 'center', name: 'Total Return', size: 2 },
  { alignment: 'center', name: 'Annualized Return', size: 2 },
  { alignment: 'center', name: 'Net Deposits', size: 2 },
  { alignment: 'center', name: 'Fee', size: 2 },
];

enum MonthlyReturnProperties {
  fees = 'fees',
  net_deposits = 'net_deposits',
  returns_in_dollars = 'returns_in_dollars',
  returns_percentage_annualized = 'returns_percentage_annualized',
  fee_shifted = 'fee_shifted',
}

const getTimeFilterTimePeriodText = (timeFilter: TimeFilterOption) => {
  switch (timeFilter) {
    case TimeFilterOption.THREE_MONTH:
      return 'Past 3 Months';
    case TimeFilterOption.SIX_MONTH:
      return 'Past 6 Months';
    case TimeFilterOption.TWELVE_MONTH:
      return 'Past 12 Months';
    case TimeFilterOption.ALL:
      return 'All Time';
  }

  return null;
};

const getTimeFilterRollup = (
  data: MonthlyReturn[],
  timeFilter: TimeFilterOption,
  property: MonthlyReturnProperties,
) => {
  // filter out dates
  // because we're not showing the current month, we need to shift 1 month
  return data
    ?.filter((monthlyReturn: MonthlyReturn) =>
      isDateInTimeFilter(
        dayjs(monthlyReturn.start_date).add(1, 'month').format('YYYY-MM-DD'),
        timeFilter,
      ),
    )
    ?.reduce(
      (sum: number, a: MonthlyReturn) => sum + Number(a[property] || 0),
      0,
    );
};

const getTimeFilterRollupPercentage = (
  data: MonthlyReturn[],
  timeFilter: TimeFilterOption,
  property: MonthlyReturnProperties,
) => {
  // filter out dates
  // because we're not showing the current month, we need to shift 1 month

  const filter = data?.filter(
    (monthlyReturn: MonthlyReturn) =>
      isDateInTimeFilter(
        dayjs(monthlyReturn.start_date).add(1, 'month').format('YYYY-MM-DD'),
        timeFilter,
      ) &&
      Number(monthlyReturn[property]) !== 0 &&
      Number(monthlyReturn[property]) > -30 &&
      Number(monthlyReturn[property]) < 30,
  );
  return (
    filter?.reduce(
      (sum: number, a: MonthlyReturn) => sum + Number(a[property] || 0),
      0,
    ) / filter.length
  );
};

const showDashInsteadOfZero = (number: number) => {
  if (number === 0) {
    return '-';
  }

  return <Currency number={number} variant={CurrencyVariant.Full} />;
};

const showDashInsteadOfZeroPercentage = (number: number) => {
  if (number === 0) {
    return '-';
  }

  if (number > 30 || number < -30) {
    return '-';
  }

  return <Percentage number={number} decimalScale={2} />;
};

interface ReturnsTableProps {
  data: MonthlyReturn[];

  timeFilter: TimeFilterOption;
}

export const ReturnsTable: React.FC<ReturnsTableProps> = ({
  data,
  timeFilter,
}) => {
  const { trackEvent } = useTracking<{
    clickText: string;
    component: string;
    eventName: TrackEventName;
  }>({
    component: 'Return Table',
  });

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: fees, isLoading: isLoadingFees } = useFees(business?.Id);

  const handleExpandClick = () => {
    setCollapsed(!collapsed);

    trackEvent({
      clickText: 'Collapse/Expand Data',
      eventName: TrackEventName.Click,
    });
  };

  const [collapsed, setCollapsed] = useState(false);

  const tableData = [];

  const sortedData = data?.sort(
    (a: MonthlyReturn, b: MonthlyReturn) =>
      Number(new Date(b.start_date)) - Number(new Date(a.start_date)),
  );

  // todo: this should be handled in custodian
  // shift fees by 1 month
  sortedData?.map((monthlyReturn: MonthlyReturn, index: number) => {
    // filter out dates
    // because we're not showing the current month, we need to shift 1 month
    if (
      !isDateInTimeFilter(
        dayjs(monthlyReturn.start_date).add(1, 'month').format('YYYY-MM-DD'),
        timeFilter,
      )
    ) {
      return;
    }

    monthlyReturn.fee_shifted = 0;

    // shift fees by 1 month
    if (index === 0) {
      // get latest fee from useFees (if exists)
      const latestFee = fees?.find(
        (x: { calcDate: string }) =>
          x.calcDate.substr(0, 10) === monthlyReturn.start_date,
      )?.amount;
      monthlyReturn.fee_shifted = Number(latestFee || 0);
    } else {
      monthlyReturn.fee_shifted = Number(sortedData[index - 1].fees || 0);
    }
  });

  if (
    timeFilter === TimeFilterOption.THREE_MONTH ||
    timeFilter === TimeFilterOption.SIX_MONTH ||
    timeFilter === TimeFilterOption.TWELVE_MONTH ||
    timeFilter === TimeFilterOption.ALL
  ) {
    const row = [
      wrapTableText(getTimeFilterTimePeriodText(timeFilter)),
      wrapTableText(
        showDashInsteadOfZero(
          getTimeFilterRollup(
            sortedData,
            timeFilter,
            MonthlyReturnProperties.returns_in_dollars,
          ),
        ),
      ),
      wrapTableText(
        showDashInsteadOfZeroPercentage(
          getTimeFilterRollupPercentage(
            sortedData,
            timeFilter,
            MonthlyReturnProperties.returns_percentage_annualized,
          ),
        ),
      ),
      wrapTableText(
        showDashInsteadOfZero(
          getTimeFilterRollup(
            sortedData,
            timeFilter,
            MonthlyReturnProperties.net_deposits,
          ),
        ),
      ),
      wrapTableText(
        showDashInsteadOfZero(
          getTimeFilterRollup(
            sortedData,
            timeFilter,
            MonthlyReturnProperties.fee_shifted,
          ),
        ),
      ),
    ];

    tableData.push(row);
  }

  sortedData?.map((monthlyReturn: MonthlyReturn, index: number) => {
    // filter out dates
    // because we're not showing the current month, we need to shift 1 month
    if (
      !isDateInTimeFilter(
        dayjs(monthlyReturn.start_date).add(1, 'month').format('YYYY-MM-DD'),
        timeFilter,
      )
    ) {
      return;
    }

    const row = [
      wrapTableText(dayjs(monthlyReturn.start_date).format('MMMM YYYY')),
      wrapTableText(
        showDashInsteadOfZero(Number(monthlyReturn.returns_in_dollars)),
      ),
      wrapTableText(
        showDashInsteadOfZeroPercentage(
          Number(monthlyReturn.returns_percentage_annualized),
        ),
      ),
      wrapTableText(showDashInsteadOfZero(Number(monthlyReturn.net_deposits))),
      wrapTableText(showDashInsteadOfZero(Number(monthlyReturn.fee_shifted))),
    ];

    tableData.push(row);
  });
  const showCollapsed = tableData.length > 5;

  return (
    <>
      <TableHeader columns={columnsReturnsTable} />

      <TableBody
        columns={columnsReturnsTable}
        data={showCollapsed ? tableData.slice(0, 2) : tableData}
      />

      {showCollapsed && (
        <>
          <Collapse in={collapsed}>
            <TableBody
              columns={columnsReturnsTable}
              data={tableData.slice(2)}
            />
          </Collapse>

          <Styled.Divider />

          <Box textAlign="center">
            <Styled.CollapseActionBox
              display="inline-block"
              mt={3}
              onClick={handleExpandClick}
            >
              {collapsed ? 'Collapse' : 'Expand'} Data{' '}
              <Box display="inline-block" ml={1} position="relative" top={-2}>
                <Styled.AccordionIcon collapsed={collapsed} />
              </Box>
            </Styled.CollapseActionBox>
          </Box>
        </>
      )}
    </>
  );
};
